import { head, pipe, ifElse, propOr, isEmpty, always, find, propEq } from 'ramda'
import debounce from 'lodash.debounce'
import { validateEmail } from 'src/services/OrganisationInvitesService'
import {
  getEmailValidationErrorCode,
  getOrganisationInviteIdFromError,
  EMAIL_VALIDATION_ERRORS
} from 'src/utils/errors'

export const getHeadErrorOrEmptyObj = pipe(
  propOr([], 'errors'),
  ifElse(isEmpty, always({}), head)
)

export const validateField = (schema, values) =>
  debounce(
    (name, callback = () => {}) =>
      schema
        .validateAt(name, values)
        // eslint-disable-next-line standard/no-callback-literal
        .then(() => callback({ valid: true, errors: [] }))
        .catch(callback),
    200
  )

export const validateValues = schema =>
  debounce((values, callback) => schema.isValid(values).then(callback), 400)

export const preventSubmitOnEnter = keyEvent => {
  if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
    keyEvent.preventDefault()
  }
}

export const validateEmailByError = error => async email => {
  try {
    await validateEmail({ emails: [email] })
    return Promise.resolve(true)
  } catch (e) {
    return getEmailValidationErrorCode(e) === error
      ? Promise.resolve(false)
      : Promise.resolve(true)
  }
}

export const hasEmailAnInvitationSent = async email => {
  try {
    await validateEmail({ emails: [email] })
    return Promise.resolve(false)
  } catch (e) {
    return getEmailValidationErrorCode(e) ===
      EMAIL_VALIDATION_ERRORS.pendingInvitation
      ? Promise.resolve(getOrganisationInviteIdFromError(e))
      : Promise.resolve(false)
  }
}

export const getOptionByValue = value => options => pipe(
  find(
    propEq('value', value)
  )
)(options)
