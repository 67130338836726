import styled from 'styled-components'

export default styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 17px;
  font-weight: 500;
  padding: 0 0 20px 0;
  margin: 0 0 20px 0;
  border-bottom: 1px solid ${props => props.theme.palette.common.alto};
`
