import { string, object, ref, boolean } from 'yup';
import {
  validateValues,
  validateField,
  validateEmailByError
} from 'src/utils/form'
import { EMAIL_VALIDATION_ERRORS } from 'src/utils/errors'

// Sign in INIT form validator

export const registrationEmailSchema = object().shape({
  email: string()
    .required(() => ({ key: 'form.email.errors.required' }))
    .email(() => ({ key: 'form.email.errors.invalid' }))
    .test(
      'is-organisation-member',
      () => ({ key: 'form.email.errors.exists' }),
      validateEmailByError(EMAIL_VALIDATION_ERRORS.emailAlreadyExists)
    )
})

export const validateRegistrationInitField = values =>
  validateField(registrationEmailSchema, values)
export const validateRegistrationInitValues = validateValues(
  registrationEmailSchema
)

// Sign in NEW form validator

export const registrationSchema = object().shape({
  email: string()
    .required(() => ({ key: 'form.email.errors.required' }))
    .email(() => ({ key: 'form.email.errors.invalid' }))
    .test(
      'is-organisation-member',
      () => ({ key: 'form.email.errors.exists' }),
      validateEmailByError(EMAIL_VALIDATION_ERRORS.emailAlreadyExists)
    ),
  password: string()
    .required(() => ({ key: 'form.password.errors.required' }))
    .min(6, () => ({
      key: 'form.password.errors.tooShort',
      options: { number: 6 }
    })),
  firstName: string().required(() => ({
    key: 'form.firstName.errors.required'
  })),
  lastName: string().required(() => ({ key: 'form.lastName.errors.required' })),
  organisationName: string().required(() => ({
    key: 'form.organisationName.errors.required'
  })),
  organisationCountry: string().required(() => ({
    key: 'form.country.errors.required'
  })),
  jobFunction: string().required(() => ({
    key: 'form.jobFunction.errors.required'
  }))
})

export const validateRegistrationField = values =>
  validateField(registrationSchema, values)
export const validateRegistrationValues = validateValues(registrationSchema)

// Sign in INVITED form validator

export const registrationInvitedSchema = object().shape({
  password: string()
    .required(() => ({ key: 'form.password.errors.required' }))
    .min(6, () => ({
      key: 'form.password.errors.tooShort',
      options: { number: 6 }
    })),
  firstName: string().required(() => ({
    key: 'form.firstName.errors.required'
  })),
  lastName: string().required(() => ({ key: 'form.lastName.errors.required' })),
  jobFunction: string().required(() => ({
    key: 'form.jobFunction.errors.required'
  }))
})

export const validateRegistrationInvitedField = values =>
  validateField(registrationInvitedSchema, values)
export const validateRegistrationInvitedValues = validateValues(
  registrationInvitedSchema
)

// Log in form validator

export const loginSchema = object().shape({
  email: string()
    .required(() => ({ key: 'form.email.errors.required' }))
    .email(() => ({ key: 'form.email.errors.invalid' })),
  password: string().required(() => ({ key: 'form.password.errors.required' }))
})

export const validateLoginField = values => validateField(loginSchema, values)
export const validateLoginValues = validateValues(loginSchema)

// Reset password init schema

export const resetPasswordInitSchema = object().shape({
  email: string()
    .required(() => ({ key: 'form.email.errors.required' }))
    .email(() => ({ key: 'form.email.errors.invalid' })),
  e2e: boolean()
})

export const validateResetPasswordInitField = values =>
  validateField(resetPasswordInitSchema, values)
export const validateResetPasswordInitValues = validateValues(
  resetPasswordInitSchema
)

// Reset password init schema

export const resetPasswordFinishSchema = object().shape({
  password: string()
    .required(() => ({ key: 'form.password.errors.required' }))
    .min(6, () => ({
      key: 'form.password.errors.tooShort',
      options: { number: 6 }
    })),
  repeatPassword: string()
    .required(() => ({ key: 'form.repeatPassword.errors.required' }))
    .oneOf([ref('password'), null], () => ({
      key: 'form.repeatPassword.errors.dontMatch'
    }))
})

export const validateResetPasswordFinishField = values =>
  validateField(resetPasswordFinishSchema, values)
export const validateResetPasswordFinishValues = validateValues(
  resetPasswordFinishSchema
)

// Update Profile init schema

export const updateProfileSchema = object().shape({
  firstName: string().required(() => ({
    key: 'form.firstName.errors.required'
  })),
  lastName: string().required(() => ({ key: 'form.lastName.errors.required' })),
  nickname: string(),
  phoneNumber: string()
    .nullable()
    .matches(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$|^$|^\s$/g, () => ({
      key: 'form.phoneNumber.errors.invalid'
    })),
  jobFunction: string().required(() => ({
    key: 'form.jobFunction.errors.required'
  }))
})

export const validateUpdateProfileField = values =>
  validateField(updateProfileSchema, values)
export const validateUpdateProfileValues = validateValues(updateProfileSchema)
